/*body {*/
    /*padding-top: 70px;*/
/*}*/

/* .app-container {
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
} */

nav {
    -webkit-box-shadow: 0 5px 25px 0 rgba(82, 82, 82, 1);
    -moz-box-shadow: 0 5px 25px 0 rgba(82, 82, 82, 1);
    box-shadow: 0 5px 25px 0 rgba(82, 82, 82, 1);
}

header {
    margin-bottom: 50px;
}

header form {
    padding-left: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.delete-btn {
    margin-left: auto;
    margin-right: 20px;
}

header form .search-box {
    margin-left: 20px;
    width: 200px;
    font-size: 14px;
}

.panel-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.snackbar-message {
    font-size: 16px;
    display: flex;
    justify-content: center;
    font-family: sans-serif;
    font-weight: lighter;
}

.selected .panel-body {
    background-color: #F2AE9E;
}

.card .panel-body {
    height: 60px;
    overflow: auto;
}


/* LOADER */
.loading-modal {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: #eeeeee99;
    z-index: 6;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.loader-text {
    font-size: 15px;
    margin-top: 10px;
}

.spinner {
    position: relative;
}
.spinner:before, .spinner:after {
    content: "";
    position: relative;
    display: block;
}
.spinner:before {
    animation: spinner 2.5s cubic-bezier(0.75, 0, 0.5, 1) infinite normal;
    width: 4em;
    height: 4em;
    background-color: cornflowerblue;
}
.spinner:after {
    animation: shadow 2.5s cubic-bezier(0.75, 0, 0.5, 1) infinite normal;
    bottom: -.5em;
    height: .25em;
    border-radius: 50%;
    background-color: rgba(#000,0.2);
}
  
@keyframes spinner {
    50% {
      border-radius: 50%;
      transform: scale(0.5) rotate(360deg);
    }
    100% {
      transform: scale(1) rotate(720deg);
    }
}
@keyframes shadow {
    50% {
      transform: scale(0.5);
      background-color: rgba(#000,0.1);
    }
}


/* MEDIA QUERIES */
@media only screen and (max-width: 767px) {
    body {
        padding-top: 130px;
    }

    .form-group {
        margin-top: 5px;
    }
}